


/*


.fc-unthemed {
    th, td, thead, tbody, .fc-divider, .fc-row, .fc-content, .fc-popover, .fc-list-view, .fc-list-heading td {
        border-left-width: 0px;
    }
}
    


.fc-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    width: 88px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 2px;
    overflow: visible;
    transform: translate3d(0,0,0);
    text-shadow: unset;

    @media screen and (max-width: 596px) {
        width: 50px;
    }
    &.fc-state-active {
        background-color: var(--app-color-primary-500)!important;
        color: white!important;
        z-index: 0;
    }
    
    &.fc-state-default,
    &.fc-state-hover {
        color: #333333;
        background-color: #e6e6e6; 
    }
}


*/

/*

This is the Material Design theme for FullCalendar Weekly Agenda view

Creation Date: Aug 19th 2015
Author: Jacky Liang
Version: FullCalendar 2.4.0
Tested Using the Following FC Settings:

    editable: false,
    handleWindowResize: true,
    weekends: false, // Hide weekends
    defaultView: 'agendaWeek', // Only show week view
    header: false, // Hide buttons/titles
    minTime: '07:30:00', // Start time for the calendar
    maxTime: '22:00:00', // End time for the calendar
    columnFormat: {
        week: 'ddd' // Only show day of the week names
    },
    displayEventTime: true,
    allDayText: 'Online/TBD'

Note: This has NOT been tested on Monthly or Daily views.

Colors: Use the following - https://www.google.com/design/spec/style/color.html#color-color-palette
        at the 700 level. An opacity of 0.65 is automatically applied to the
        700 level colors to generate a soft and pleasing look.

        Color were applied to each event using the following code:

        events.push({
            title: 'This is a Material Design event!',
            start: 'someStartDate',
            end: 'someEndDate',
            color: '#C2185B'
        });

*/

.fc-widget-content {
    // cursor: pointer;
}

.fc-highlight {
    //background-color: var(--app-color-primary-100);
    @apply bg-primary-200 text-primary-700 dark:bg-primary-700 dark:text-primary-100 #{'!important'};
    //box-shadow: 0px 3px 1px -2px rgba(var(--app-color-primary-600), 0.2), 0px 2px 2px 0px rgba(var(--app-color-primary-600), 0.14), 0px 1px 5px 0px rgba(var(--app-color-primary-600), 0.12);
    //text-align: center;
    //font-size: 20px;
    /*
    &::before {
        font-family: "Material Icons";    
        content: "\e147";
    }
    */
}
/*
// Highlight the hovered timeslot (accross all resources)
tr.fc-minor {
    td.fc-widget-content {
        &:hover {
            background-color: rgba(var(--app-color-primary-100), 0.4);
            color: var(--app-color-primary-600);
            box-shadow: 0px 3px 1px -2px rgba(var(--app-color-primary-600), 0.2), 0px 2px 2px 0px rgba(var(--app-color-primary-600), 0.14), 0px 1px 5px 0px rgba(var(--app-color-primary-600), 0.12);
            text-align: center;
            font-size: 24px;
            

            &::before {
                font-family: "Material Icons";    
                content: "\e147";
                vertical-align: bottom;
                padding-top: 20px;
            }
        }
    }
}
*/

/* Remove that awful yellow color and border from today in Schedule */
.fc-state-highlight {
    opacity: 0;
    border: none;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
    border-radius: 4px;
    border: none;
    padding: 5px;
    opacity: .65;
    left: 5% !important;
    right: 5% !important;
}

/* Bolds the name of the event and inherits the font size */
.fc-event {
    font-size: inherit !important;
    // font-weight: bold !important;
}

/* Remove the header border from Schedule (week view) */
.fc td, .fc th {
    border-width: 1px !important;
    // padding: 0px 5px !important;
    vertical-align: top !important;

    .fc-resource-cell {
        cursor: pointer;
        padding: 15px 0px 15px 0px!important;
        // background-color: rgba(red(var(--app-color-primary-500)), green(var(--app-color-primary-500)), blue(var(--app-color-primary-500)), 0.5);
        font-weight: 600;
        font-size: 15px;
        @apply text-slate-900 dark:text-slate-100 #{'!important'};
        // background-color: var(--app-color-grey-200);
        
    }
}

/* Remove the header border from Schedule (list view) */
.fc-list-table td, fc-list-table th {
    border-style: none !important;
    border-width: 1px !important;
    padding: 10px 5px !important;
    vertical-align: top !important;
    font-family: 'Roboto',Helvetica,Arial,sans-serif !important;
    font-weight: 400;
}


/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
    z-index: 1 !important;
    background: inherit !important;
    opacity: .25 !important;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
    font-weight: normal !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
    opacity: .65 !important;
    margin-left: 12px !important;
    padding: 5px !important;
}

/* Apply background to all day events */
.fc-bg .fc-day {
    @apply bg-white dark:bg-slate-800 #{'!important'};
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
    opacity: .65 !important;
    margin-left: 12px !important;
    padding: 5px !important;
}

/* Material design button */
.fc-button-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    min-height: 36px;
    min-width: 88px;
    line-height: 36px;
    vertical-align: middle;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    border: 0;
    padding: 0 6px;
    letter-spacing: .01em;
    background: transparent;
    color: currentColor;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 600;
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    text-decoration: none;
    overflow: hidden;
    -webkit-transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
    transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
    margin: 0;
    width: 88px;
    transform: translate3d(0,0,0);
    text-shadow: unset;
    color: white!important;
    @apply bg-primary-600 border-primary-600 text-white #{'!important'};
    
    &.fc-button-active {
        @apply bg-primary-200 border-primary-200 text-primary-900 #{'!important'};
    }

    @media screen and (max-width: 596px) {
        width: 50px;
    }
}

.fc-button:hover {
    background-color: rgba(158,158,158,0.2);
}

.fc-button:focus, .fc-button:hover {
    text-decoration: none;
}

/* The active button box is ugly so the active button will have the same appearance of the hover */
.fc-state-active {
    background-color: rgba(158,158,158,0.2);
}

/* Not raised button */
.fc-state-default {
    box-shadow: None;
}


/*
    ------------------------------------------------------------------------------
    My modifications
    ------------------------------------------------------------------------------
*/


.fc-view-container {
    // background-color: white;
}

.fc-toolbar.fc-header-toolbar {
    margin: 0px 15px;
    
    h2 {
        font-weight: 400;
        @apply text-default #{'!important'};
        font-size: 17px!important;
        font-weight: 400;
        
        /* mobile display */
        @media screen and (max-width: 1279px) {
            @apply text-default #{'!important'};
            font-size: 14px!important;
            font-weight: 400;
        }

    }
    @media screen and (max-width: 599px) {
        padding-left: 15px;
        margin-right: 15px;
        font-size: 15px;
    }
}

.fc-view {
    font-family: 'Roboto',Helvetica,Arial,sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    @apply bg-slate-100 #{'!important'};
    margin: 1rem!important;
    border-radius: 8px!important;
    
    @media screen and (max-width: 599px) {
        // box-shadow: unset;
        // border: unset;
        // padding: 15px 5px 20px 0px !important;
    }
    
    & > table {
        background-color: transparent;
        @apply border-slate-300 #{'!important'};
        border-radius: 8px!important;

    }

    &.fc-list-view {
        height: auto;

        @media screen and (max-width: 599px) {
            padding-left: 10px!important;
            padding-right: 10px!important;

            .fc-list-table td, fc-list-table th {
                font-size: 14px;
            }
        }
        .fc-scroller {
            height: auto!important;
        }
    } 
}

.fc-unthemed thead {
    margin-bottom: 10px;
}

.fc-day-header {
    text-align: center!important;
    @apply text-slate-900 bg-slate-200 dark:text-slate-100 dark:bg-slate-800 #{'!important'};
    font-weight: 400;
    padding-right: 10px!important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.fc-widget-header {
    @apply text-slate-900 bg-slate-200 dark:text-slate-100 dark:bg-slate-800 #{'!important'};
}


.fc-axis {
    padding: 0 10px;

    &.fc-time {
        text-align: right !important;
        font-weight: 400;
        @apply text-slate-900 bg-slate-200 dark:text-slate-100 dark:bg-slate-800 #{'!important'};
    }
}

.fc-list-heading-main {
    font-weight: 600;
}

.fc-button {
    padding: 0px!important;
    
    &.fc-next-button,
    &.fc-prev-button {
        max-width: 50px;
        width: 50px;
        min-width: 0px;
        font-family: 'Roboto',Helvetica,Arial,sans-serif !important;
        font-size: 14px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }
    &.fc-today-button,
    &.fc-listMonth-button,
    &.fc-timeGridWeek-button,
    &.fc-resourceTimeGridDay-button {
        max-width: 50px;
        width: 50px;
        min-width: 0px;
        font-family: 'Roboto',Helvetica,Arial,sans-serif !important;
        font-size: 14px;
        font-weight: 400;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.2px;
    }
}

.fc-today {
  background-color:inherit !important;
}

.fc-list-item.fullcalendar-event {
    cursor: pointer;
    @apply text-slate-900 bg-slate-200 dark:text-slate-100 dark:bg-slate-800 #{'!important'};
}

.fc-event.fullcalendar-event {
    border-radius: 2px!important;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)!important;
    //box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12); // elevation z1
    //box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12); // elevation z2
    //box-shadow: 0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 1px 8px 0 rgba(0,0,0,.12); // elevation z3
    //box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    margin: 0;
    min-width: 88px;
    padding: 0px 4px;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);

    &.paid {
        @apply bg-primary-600 text-white dark:bg-primary-300 dark:text-primary-900 #{'!important'};
    }
    &.not-paid {
        @apply bg-warn-600 text-white dark:bg-warn-300 dark:text-warn-900 #{'!important'};
    }
    &.unknown {
        @apply bg-accent-600 text-white dark:bg-accent-300 dark:text-accent-900 #{'!important'};
    }
    &.color-1 {
        background-color: teal;
    }
    &.color-2 {
        background-color: purple;
    }
    &.color-3 {
        background-color: yellow;
    }
    &.color-4 {
        background-color: indigo;
    }
    &.color-5 {
        background-color: indigo;
    }
    &.color-6 {
        background-color: pink;
    }
    &.color-7 {
        background-color: cyan;
    }
    &.color-8 {
        background-color: red;
    }
    &.color-9 {
        background-color: green;
    }
    &.color-10 {
        background-color: blue;
    }
    &.color-11 {
        background-color: orange;
    }
    &.color-12 {
        background-color: brown;
    }
    &.day-off {
        @apply bg-slate-200 #{'!important'};
        box-shadow: unset!important;
    }
    .fc-time {
        font-weight: 600;
    }
    .fc-event-main-frame {
        color: white;
    }
}

.loading {

    .fc-view {
        -webkit-animation-duration: 2s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeHolderShimmer;
        -webkit-animation-timing-function: linear;
        @apply bg-slate-100 #{'!important'};
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    
}

@-webkit-keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    100% {
        background-position: 468px 0
    }
}