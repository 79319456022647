/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* Overrides */
@use 'overrides/angular-material';

/* CK Editor */
@use 'overrides/ck-editor.scss';

/* Full Calendar */
@use 'overrides/fullcalendar.scss';

/* contain overscroll behaviour only in vertical direction */
body {
    overscroll-behavior-y: contain;
}