
/* ----------------------------------------------------------------------------------------------------- */
/*  @ CK Editor
/* ----------------------------------------------------------------------------------------------------- */

.ck.ck-toolbar {
    @apply bg-slate-100 border-slate-300 rounded-t-md #{'!important'};
}

.ck.ck-editor__main {
    @apply bg-white #{'!important'};
}

.ck-editor__editable_inline {
    
    min-height: 100px;
    
    @apply rounded-b-md border-slate-300 #{'!important'};

    h2 {
        @apply text-2xl font-bold leading-loose #{'!important'};
    }

    h3 {
        @apply text-xl font-semibold leading-loose #{'!important'};
    }

    h4 {
        @apply text-lg font-medium leading-loose #{'!important'};
    }

    @media (prefers-color-scheme: dark) {
        @apply bg-transparent dark:text-gray-100 #{'!important'};
    }
}
