/* ----------------------------------------------------------------------------------------------------- */
/*  @ Angular Material CDK helpers & overrides
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Table
/* ----------------------------------------------------------------------------------------------------- */

/*
.mat-table {
    @apply bg-transparent dark:bg-transparent #{'!important'};
    background: transparent!important;
}
*/

.mat-mdc-tab-label-container {
    @apply mx-0 #{'!important'};
}

.mat-mdc-dialog-title {
    @apply text-2xl font-medium #{'!important'};
}

body.dark .mat-focused .mat-form-field-required-marker,
body .dark .mat-focused .mat-form-field-required-marker {
    color: white;
}

.mat-tab-group .mat-tab-body-content {
    @apply px-0 md:px-2 lg:px-6 #{'!important'};
}


/* ----------------------------------------------------------------------------------------------------- */
/*  @ Checkbox
/* ----------------------------------------------------------------------------------------------------- */

// Wrap text
.mat-mdc-list-item-unscoped-content {
    @apply whitespace-normal #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Tabs
/* ----------------------------------------------------------------------------------------------------- */

.mat-mdc-tab-body-content {
    @apply px-0 md:px-2 lg:px-6 #{'!important'};
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Snackbars
/* ----------------------------------------------------------------------------------------------------- */

.mdc-snackbar {
    button {
        @apply bg-primary text-white #{'!important'};
    }
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Trees
/* ----------------------------------------------------------------------------------------------------- */

.mat-tree-node {

    user-select: none;
    cursor: move;

    &.cdk-drag-preview {
        // while dragging
        @apply bg-slate-100 dark:bg-slate-800 border-slate-300 dark:border-slate-600 drop-shadow-xl #{'!important'};
    }

    &.cdk-drag-placeholder {
        // potential drop area
        opacity: 0;
    }
}

/* items moving away to make room for drop */
.cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* item being dropped */
.cdk-drag-animating {
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}